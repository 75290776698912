<template>
  <main class="page bg-mid-black">
    <header class="page-header bg-black">
      <img src="@/assets/global-icons/spacein_logo_white.svg" alt="" @click="routeToHome">
    </header>
    <section class="page-body">
      <div class="body-image">
        <img src="@/assets/images/login.png"  />
      </div>
      <div class="body-content">
        <slot></slot>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginPagesFrame',
  methods: {
    ...mapActions({routeToHome: 'routing/goToHome'}),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/scss/components/authentication/login_pages_frame.scss';
</style>
