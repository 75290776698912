<template>
  <div class="space-invites">
    <login-pages-frame>
      <div class="space-invites__form padding-md bg-light-black" v-if="!loadingInviteData">

        <div class="space-invites__respond-to-invites" v-if="validInvite && ifAuthenticated">
            <h1 class="space-invites__space-name" v-if="inviteStatus === 'unused'">You have been invited to <br> {{spaceName}}</h1>
            <div class="space-invites__respond" v-if="inviteStatus === 'unused'">
              <btn class="space-invites__accept" rounded wide large :customClasses="['margin-auto', 'text-white', 'bg-green']"
                   @onClick="() => handleAcceptSpaceInvite([inviteToken, {...inviteData, areaId: inviteData.spaceId}])"
              >
                <template #text>
                  <b>Accept Invite</b>
                </template>
              </btn>
              <btn class="space-invites__reject" rounded wide large :customClasses="['margin-auto', 'bg-red', 'text-white']"
                   @onClick="() => handleRejectSpaceInvite([inviteToken, inviteData])"
              >
                <template #text>
                  <b>Decline Invite</b>
                </template>
              </btn>
            </div>
          </div>

          <div v-else>
            <h1 class="space-invites__space-name" v-if="inviteToken && !ifAuthenticated && ifCheckedAuth">Please login or register to <br> respond to this invite</h1>

            <div class="space-invites__space-name" v-else-if="inviteStatus === 'emailMismatch' && !isGuest">
              <p>This invite was intended for someone else.</p>
              <br>
              <p>Please check your invite and register with the email that is used in the invitation.</p>
            </div>
            <div class="space-invites__space-name" v-else-if="isGuest">
              <p>You are logged in as a guest.</p>
              <br>
              <p>Please register with the email that is used in the invitation to proceed.</p>
            </div>

            <div class="space-invites__space-name" v-else-if="inviteStatus === 'cancelled'">
              <p>This invite has been cancelled. <br><br> Please request a new invite from the Space administrator.</p>
            </div>

            <div class="space-invites__space-name" v-else-if="inviteStatus === 'joined'">
              <p>You have already accepted this invite!</p>
            </div>
            <div class="space-invites__space-name" v-else-if="inviteStatus === 'rejected'">
              <p>You have already declined this invite. <br><br> If you have changed your mind, <br> you will need to request a new invite from the Space administrator.</p>
            </div>

            <div class="space-invites__space-name" v-else>
              <p>This invite is no longer valid.</p>
              <br><br>
              <p>You will need to request a new invite from the Space administrator.</p>
            </div>

            <div class="space-invites__respond">
              <btn class="space-invites__accept" rounded wide large :customClasses="['margin-auto', 'bg-green', 'text-white']"
                   @onClick="routeToLoginWithRedirect"
              >
                <template #text>
                  <b v-if="!ifAuthenticated || isGuest">Login or Register</b>
                  <b v-else>Back To Spacein</b>
                </template>
              </btn>
              <btn class="space-invites__accept" rounded wide large :customClasses="['margin-auto', 'bg-red', 'text-white']"
                   @onClick="logout"
                   v-if="ifAuthenticated"
              >
                <template #text>
                  <b><span v-if="isGuest">Logout of Guest Account</span><span v-else>Switch Account</span></b>
                </template>
              </btn>
            </div>
          </div>

      </div>
    </login-pages-frame>
  </div>
</template>

<script>
import LoginPagesFrame from '@/components/authentication/LoginPagesFrame.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SpaceInvites',
  components: {
    LoginPagesFrame
  },
  data: () => ({
    inviteToken: null,
    loadingInviteData: true,
    inviteData: {}
  }),
  computed: {
    ...mapGetters('api/auth', {
      ifAuthenticated: 'authenticated',
      ifCheckedAuth: 'ifCheckedAuth',
    }),
    ...mapGetters(['current_user/data']),
    validInvite() {
      return this.inviteToken && this.inviteStatus === 'unused'
    },
    inviteEmail() {
      return this.inviteData?.email || ''
    },
    inviteStatus() {
      return this.inviteData?.status || ''
    },
    spaceName() {
      return this.inviteData?.spaceName || 'a new Space'
    },
    isGuest() { //if user data is present but no email we assume they are a guest
      return this.inviteStatus === 'isGuest' || false
    }
  },
  watch: {
    ifAuthenticated() {
      this.getInviteData()
    },
  },
  methods: {
    ...mapActions([
      'handleAcceptSpaceInvite',
      'handleRejectSpaceInvite',
      'handleGetSpaceInviteData',
    ]),
    ...mapActions('routing', ['goToHome', 'goToLogin', 'getQueryValue']),
    ...mapActions('api/auth', ['logout']),
    async getInviteTokenFromURL() {
      this.inviteToken = await this.getQueryValue('token')
    },
    async routeToLoginWithRedirect() {
      if(!this.ifAuthenticated) this.goToLogin()
      else this.goToHome()
    },
    async getInviteData() {
      if(this.ifAuthenticated) {
        this.inviteData = await this.handleGetSpaceInviteData(this.inviteToken) || {}
      }
      this.loadingInviteData = false
    },
  },
  async mounted() {
    await this.getInviteTokenFromURL()
    this.getInviteData()
  }
};
</script>
<style scoped lang="scss">
@import '~@/scss/pages/space_invites.scss';
</style>
