import { render, staticRenderFns } from "./LoginPagesFrame.vue?vue&type=template&id=4186ae4f&scoped=true"
import script from "./LoginPagesFrame.vue?vue&type=script&lang=js"
export * from "./LoginPagesFrame.vue?vue&type=script&lang=js"
import style0 from "./LoginPagesFrame.vue?vue&type=style&index=0&id=4186ae4f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4186ae4f",
  null
  
)

export default component.exports