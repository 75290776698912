<template>
  <div class="page create-space">
    <h2 class="create-space__header">Create new Space</h2>
    <section class="create-space__body">
      <TextInput huge v-model="spaceName" placeholder="Type a name of your Space" class="create-space__input" />

      <FooterButtons class="create-space__buttons">
        <btn huge colour="ghost" @onClick="cancelCreation">
          <template #text> Cancel </template>
        </btn>
        <btn huge colour="gold" :disabled="isDisabled" @onClick="createArea">
          <template #text> {{ creatingArea ? 'Creating Space...' : 'Create Space' }}</template>
        </btn>
      </FooterButtons>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TextInput, FooterButtons } from '@/framework';

const defaultColour = "#ffffff";

export default {
  components: {
    TextInput,
    FooterButtons
  },
  name: 'NewSpace',
  data() {
    return {
      spaceName: '',
      creatingArea: false,
    };
  },
  computed: {
    ...mapGetters(['getIfAreaBackgroundIsLightOrDark']),
    ...mapGetters({
      currentUsersEmail: 'current_user/email',
      isMobile: 'viewport/isMobile'
    }),
    isDisabled() {
      return this.spaceName.length < 1;
    },
  },
  methods: {
    ...mapActions([
      'showErrorAddUsersToAreaInvalidAndAlreadyAddedPopup',
      'showErrorAddUsersToAreaInvalidPopup',
      'showErrorAddUsersToAreaAlreadyAddedPopup',
      'handleCreateSpace',
      'loading/load',
      'loading/unload',
      'sidebar/close'
    ]),
    ...mapActions('analytics', { createSpaceTracking: 'createSpace' }),
    ...mapActions('routing', ['goToHome', 'goToArea']),
    cancelCreation() {
      this.goToHome();
    },
    async createArea() {
      if (this.creatingArea) return;

      this.creatingArea = true
      this.spaceName = this.spaceName.trim()

      try {
        let res = await this.handleCreateSpace([this.spaceName, defaultColour])

        if (res.success) {
          this.goToArea(res.success.id)
        } else {
          this.creatingArea = false
        }
      } catch {
        this.creatingArea = false
      }
    }
  },
  mounted() {
    this['sidebar/close']();

    this.createSpaceTracking(['Create Space Initialised', 'initialised']);
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/pages/newspace.scss';
</style>
